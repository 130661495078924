import React, { Component, Fragment } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Col, Row, Label, Nav, NavItem, NavLink, Card, Button, UncontrolledTooltip, Popover, PopoverHeader, PopoverBody, FormGroup, Alert } from 'reactstrap'
import Loader from "../../../Material/Loader"
import "react-awesome-lightbox/build/style.css"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import gql from 'graphql-tag'
import { Link } from "react-router-dom"
import DocumentModalNew from "../../../Material/DocumentModal/DocumentModalNew"
import CarIssueModal from "../../../Maintenance/CarIssueModal"
import { cleanDocumentUrl } from "../../../Material/DocumentModal/DocumentsListNew"
import Table from "reactstrap/lib/Table"
import Select from 'react-select'
import DateTimePicker from "../../../Material/DateTimePicker"

const CreateIncidentMutation = gql`mutation CreateIncidentMutation($input: CreateIncidentMutationInput!){
    createIncident(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const IncidentDamageSeverityLevelQuery = gql`{
  damageSeverityLevels: __type(name: "IncidentDamageSeverity") {
    states: enumValues {
      name
      description
    }
  }
}
`

const UpdateIncidentDamageMutation = gql`mutation UpdateIncidentDamageMutation($input: UpdateIncidentDamageMutationInput!){
    updateIncidentDamage(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const UpdateIncidentMutation = gql`mutation UpdateIncidentMutation($input: UpdateIncidentMutationInput!){
    updateIncident(input:$input){
        ok
        errors{
            messages
        }
    }
} `


const CarConditionDetailQuery = gql`query carCondition($id: ID!){
    carCondition(carConditionId:$id){
        id
        dateAdded
        dateModified
        formData
        gas
        mileage
        user{
          id
          username
        }
          incidentSet {
            edges {
                node {
                    id
                    pk
                    dateAdded
                    incidentDate
                    rental {
                        id
                        driver {
                            id
                            name
                        }
                    }
                    damageTransaction {
                        id
                        pk
                        dateAdded
                        dynamicAmount
                        notes
                        chargeType{
                            id
                            name
                        }
                    }
                    incidentdamageSet {
                        edges {
                            node {
                                id
                                projectedCost
                                severity
                                pictures{
                                    edges{
                                        node{
                                            id
                                            dateAdded
                                            pictureUrl
                                            pictureType
                                            description
                                        }
                                    }
                                }
                                damage {
                                    id
                                    car{
                                        id
                                        pk
                                    }
                                    dateAdded
                                    description: notes
                                    status
                                    reportedBy
                                    canPickup
                                    issueCategory {
                                        id
                                        name: category
                                    }
                                    vendor{
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        carpicturesSet {
          edges {
            node {
              id
              dateAdded
              pictureUrl
              pictureType
              description
            }
          }
        }
      
    }     
}`

class CarInspectionDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            error: null,
            loading: false,
            successMessage: "",
            picturesSetSubsection: "walkaroundPictures",
            carInspectionSubsection: "inspectionDetails",
            reportedIssueSubsection: "reportedIssues",
            damageTransactionSubsection: "damageTransactions",
            incidentDetailSubsection: "incidentDetial",
            activeIncidentId: this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges && this.props.carCondition.incidentSet.edges.length > 0 ? this.props.carCondition.incidentSet.edges[0].node.id : "",
            selectedIncidentId: "",
            incidentDatetime: new Date()
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    toggleSectionNav = (sectionName, value) => {
        this.setState({ [sectionName]: value })
    }

    viewPictures = (carPictures, index = 0) => {
        let pictures = carPictures.map(picture => ({ documentUrl: cleanDocumentUrl(picture.node.pictureUrl), id: picture.node.id, name: picture.node.pictureType, description: picture.node.description, dateAdded: picture.node.dateAdded }))
        this.setState({ carPictures: pictures, selectedImage: pictures[index] })
    }

    pObj = (jsonString) => {
        let obj = JSON.parse(jsonString)
        return obj
    }
    next = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === carPictures.length - 1) return
        this.setState({ selectedImage: carPictures[currentIndex + 1] })
    }

    prev = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === 0) return
        this.setState({ selectedImage: carPictures[currentIndex - 1] })
    }

    handleClose = () => {
        this.setState({
            loading: false,
            error: "",
            successMessage: "",
            openModal: "",
            selectedIncidentId: ""
        })
    }

    createNewIncident = (e) => {
        try {
            if (this.props.id) {
                this.setState({ loading: true })
                let input = {
                    carConditionId: this.props.id,
                    incidentDatetime: this.state.incidentDatetime,
                }
                this.props.client.mutate({
                    mutation: CreateIncidentMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.createIncident && result.data.createIncident.ok) {
                        this.setState({ errorMessage: "", successMessage: "Succesfully Created New Incident" })
                        this.props.refetchQuery()
                        this.handleClose()
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.createIncident && result.data.createIncident.errors && result.data.createIncident.errors[0] && result.data.createIncident.errors[0].messages) {
                            errorMessage = result.data.createIncident.errors[0].messages.toString()
                        }
                        this.setState({ error: errorMessage })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let errorMessage = "An error has occured"
                    this.setState({ error: errorMessage, loading: false })
                })
            }
            else this.setState({ error: "Missing Required Field" })
        }
        catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false })
        }
    }

    updateIncident = (incidentId) => {
        try {
            if (incidentId) {
                this.setState({ loading: true })
                let input = {
                    incidentId: incidentId,
                    incidentDatetime: this.state.incidentDatetime,
                }
                this.props.client.mutate({
                    mutation: UpdateIncidentMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateIncident && result.data.updateIncident.ok) {
                        this.setState({ errorMessage: "", successMessage: "Succesfully Updated New Incident" })
                        this.props.refetchQuery()
                        this.handleClose()
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.updateIncident && result.data.updateIncident.errors && result.data.updateIncident.errors[0] && result.data.updateIncident.errors[0].messages) {
                            errorMessage = result.data.updateIncident.errors[0].messages.toString()
                        }
                        this.setState({ error: errorMessage })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let errorMessage = "An error has occured"
                    this.setState({ error: errorMessage, loading: false })
                })
            }
            else this.setState({ error: "Missing Required Field" })
        }
        catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false })
        }
    }

    handleMoveDamage = (damageId) => {
        try {
            if (damageId) {
                this.setState({ loading: true })
                let input = {
                    incidentDamageId: damageId,
                    incidentId: this.state.selectedIncidentId,
                }
                this.props.client.mutate({
                    mutation: UpdateIncidentDamageMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateIncidentDamage && result.data.updateIncidentDamage.ok) {
                        this.setState({ error: "", successMessage: "Succesfully Moved The Damage To Selected Incident" })
                        this.props.refetchQuery()
                        this.handleClose()
                    } else {
                        let error = "An error occurred, could not complete request."
                        if (result.data && result.data.updateIncidentDamage && result.data.updateIncidentDamage.errors && result.data.updateIncidentDamage.errors[0] && result.data.updateIncidentDamage.errors[0].messages) {
                            error = result.data.updateIncidentDamage.errors[0].messages.toString()
                        }
                        this.setState({ error: error, successMessage: "" })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let error = "An error has occured"
                    this.setState({ error: error, loading: false, successMessage: "" })
                })
            }
            else this.setState({ error: "Missing Required Field", successMessage: "" })
        }
        catch (err) {
            let error = "An error has occured"
            this.setState({ error: error, loading: false, successMessage: "" })
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.carCondition !== this.props.carCondition || !this.state.activeIncidentId) && this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges.length > 0) {
            this.setState({ activeIncidentId: this.props.carCondition.incidentSet.edges[0].node.id })
        }
    }


    render() {
        const IssuesTable = [
            { id: "date_addded", name: "Date Added", sortable: true },
            { id: "issueCategory", name: "Category", sortable: false },
            { id: "status", name: "Status", sortable: false },
            { id: "projectedCost", name: "Projected Cost", sortable: false },
            { id: "severity", name: "Severity Level", sortable: false },
            { id: "canPickup", name: "Can Pickup", sortable: false },
            { id: "pictures", name: "Pictures", sortable: false },
            { id: "action", name: "Action", sortable: false },
        ]
        return (
            <>
                <Col className="zero-padding">
                    {this.state.selectedImage &&
                        <DocumentModalNew
                            document={this.state.selectedImage}
                            handleClose={() => this.setState({ selectedImage: null })}
                            next={this.next}
                            prev={this.prev}
                            objectType={"car"}
                            isPicture={true}
                            refetch={this.props.refetch}
                            currentUser={this.props.currentUser}
                        />
                    }
                    {this.props.loading ? <Loader /> : <>
                        <div className="inspection-detail">
                            <Nav tabs className="d-flex w-100">
                                <NavItem className={this.state.tab === "inspectionDetails" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carInspectionSubsection === "inspectionDetails" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carInspectionSubsection", "inspectionDetails")}>
                                        INSPECTION DETAILS
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "carIssue" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carInspectionSubsection === "carIssue" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carInspectionSubsection", "carIssue")}>
                                        REPORTED INCIDENTS
                                    </NavLink>
                                </NavItem>
                                <div className="ml-auto">
                                    {this.state.carInspectionSubsection === "carIssue" &&
                                        <div>
                                            <Button id={"addNewIncident"} className="primary" onClick={() => this.setState({ openModal: "addNewIncident" })}>Add New Incident</Button>
                                        </div>
                                    }
                                    {this.state.openModal === "addNewIncident" && this.state.carInspectionSubsection === "carIssue" &&
                                        <Popover placement="left" target={"addNewIncident"} isOpen={this.state.openModal === "addNewIncident" && this.state.carInspectionSubsection === "carIssue"} toggle={() => this.toggleModal("addNewIncident")}>
                                            <PopoverHeader>Create New Incident</PopoverHeader>
                                            <PopoverBody>
                                                <Row>
                                                    {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                                                    {this.state.successMessage && <Col xs={12}><Alert color="success">{this.state.successMessage}</Alert></Col>}
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Select Incident Datetime</Label>
                                                            <DateTimePicker
                                                                type="datetime-local"
                                                                name="incidentDatetime"
                                                                id="incidentDatetime"
                                                                value={this.state.incidentDatetime}
                                                                placeholder="Select Incident datetime"
                                                                setError={(error) => this.setState({ error })}
                                                                updateInput={(name, value) => this.setState({ [name]: value })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-right">
                                                        <Button className="Add-driver-modal-primary-btn" onClick={this.createNewIncident} disabled={this.state.loading || !(this.state.incidentDatetime)}>{this.state.loading ? "Loading..." : "Add New Incident"}</Button>{' '}
                                                    </Col>
                                                </Row>
                                            </PopoverBody>
                                        </Popover>
                                    }
                                </div>
                            </Nav>
                            <div className="info-container" style={{ margin: "0px", borderTopLeftRadius: "0px" }}>
                                <Row>
                                    <Col>
                                        {this.props.carCondition ?
                                            this.state.carInspectionSubsection === "inspectionDetails" ?
                                                <div>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Label>Date Added</Label>
                                                            <p>{this.props.carCondition.dateAdded ? <DatetimeRenderer datetime={this.props.carCondition.dateAdded} placement="right" /> : "--"}</p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>Date Modified</Label>
                                                            <p>{this.props.carCondition.dateModified ? <DatetimeRenderer datetime={this.props.carCondition.dateModified} placement="right" /> : "--"}</p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>Inspection Type</Label>
                                                            <p>
                                                                {this.props.carCondition.formData && this.pObj(this.props.carCondition.formData) && "form_type" in this.pObj(this.props.carCondition.formData) ? this.pObj(this.props.carCondition.formData)["form_type"] : "---"}
                                                            </p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>User</Label>
                                                            <p className="columnContent">
                                                                {this.props.carCondition.user ? this.props.carCondition.user.username : "---"}
                                                            </p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>Gas</Label>
                                                            <p className="columnContent">{this.props.carCondition.gas}</p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>Mileage</Label>
                                                            <p className="columnContent">{this.props.carCondition.mileage}</p>
                                                        </Col>
                                                    </Row>
                                                </div> :
                                                this.state.carInspectionSubsection === "carIssue" ?
                                                    <div>
                                                        <Row>
                                                            <Col className="inspections-list col-2" style={{ maxHeight: "370px", overflow: "auto" }}>
                                                                {this.props.loading ? <Loader /> : <>
                                                                    {this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges && this.props.carCondition.incidentSet.edges.length > 0 ? this.props.carCondition.incidentSet.edges.map((incident, i) =>
                                                                        <div className={"car-inspection" + (this.state.activeIncidentId === (incident.node.id) ? " active" : "")} onClick={() => this.setState({ activeIncidentId: incident.node.id })} key={i} >
                                                                            <div className={"sidebar-listing-wrapper-text"} >
                                                                                <p>{incident.node.pk} - {incident.node.dateAdded ? <DatetimeRenderer datetime={incident.node.dateAdded} /> : "--"}</p>
                                                                            </div>
                                                                        </div>
                                                                    ) : <div><p className="text-center no-rentals">No Car Condition Found!</p></div>
                                                                    }
                                                                </>}
                                                            </Col>
                                                            <Col>
                                                                {this.state.activeIncidentId ?
                                                                    <>
                                                                        <Row>
                                                                            <Col>
                                                                                <Nav tabs>
                                                                                    <NavItem className={this.state.tab === "incidentDetial" ? "active" : "inactive"}>
                                                                                        <NavLink className={this.state.incidentDetailSubsection === "incidentDetial" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("incidentDetailSubsection", "incidentDetial")}>
                                                                                            INCIDENT DETAILS
                                                                                        </NavLink>
                                                                                    </NavItem>
                                                                                </Nav>
                                                                                {this.state.incidentDetailSubsection === "incidentDetial" &&
                                                                                    <div className="info-container" style={{ borderTopLeftRadius: "0px", padding: 3 }}>
                                                                                        <Table responsive>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th style={{ fontWeight: 500 }}>Driver</th>
                                                                                                    <th style={{ fontWeight: 500 }}>Date Added</th>
                                                                                                    <th style={{ fontWeight: 500 }}>Incident Date &nbsp;&nbsp; <i className={"fa fa-pencil-square-o"} onClick={() => this.toggleModal("updateIncident")} id={"updateIncident"}></i></th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges && this.props.carCondition.incidentSet.edges.length > 0 ? this.props.carCondition.incidentSet.edges.filter(item => item.node.id === this.state.activeIncidentId).map((incident, i) =>

                                                                                                    <tr key={i}>
                                                                                                        <td>{incident.node.rental && incident.node.rental.driver ? incident.node.rental.driver.name : "No Driver Found!"}</td>
                                                                                                        <td>{incident.node.dateAdded ? <DatetimeRenderer datetime={incident.node.dateAdded} placement="right" /> : "---"}</td>
                                                                                                        <td>{incident.node.incidentDate ? <DatetimeRenderer datetime={incident.node.incidentDate} placement="right" /> : "---"}</td>
                                                                                                        {this.state.openModal === "updateIncident" &&
                                                                                                            <Popover placement="left" target={"updateIncident"} isOpen={this.state.openModal === "updateIncident"} toggle={() => this.toggleModal("updateIncident")}>
                                                                                                                <PopoverHeader>Update Incident</PopoverHeader>
                                                                                                                <PopoverBody>
                                                                                                                    <Row>
                                                                                                                        {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                                                                                                                        {this.state.successMessage && <Col xs={12}><Alert color="success">{this.state.successMessage}</Alert></Col>}
                                                                                                                    </Row>
                                                                                                                    <Row>
                                                                                                                        <Col>
                                                                                                                            <FormGroup>
                                                                                                                                <Label>Select Date</Label>
                                                                                                                                <DateTimePicker
                                                                                                                                    type="datetime-local"
                                                                                                                                    name="incidentDatetime"
                                                                                                                                    id="incidentDatetime"
                                                                                                                                    value={incident.node.incidentDate ? incident.node.incidentDate : new Date()}
                                                                                                                                    placeholder="Select Incident datetime"
                                                                                                                                    setError={(error) => this.setState({ error })}
                                                                                                                                    updateInput={(name, value) => this.setState({ [name]: value })}
                                                                                                                                />
                                                                                                                            </FormGroup>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                    <Row>
                                                                                                                        <Col className="text-right">
                                                                                                                            <Button className="Add-driver-modal-primary-btn" onClick={() => this.updateIncident(incident.node.id)} disabled={this.state.loading || !(this.state.incidentDatetime)}>{this.state.loading ? "Loading..." : "Update Incident"}</Button>{' '}
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </PopoverBody>
                                                                                                            </Popover>
                                                                                                        }

                                                                                                    </tr>
                                                                                                ) : <tr><td colSpan={4} style={{ color: "red" }}>No Incident Found!</td></tr>}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </div>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        <br />
                                                                        <Row>
                                                                            <Col>
                                                                                <Nav tabs>
                                                                                    <NavItem className={this.state.tab === "reportedIssues" ? "active" : "inactive"}>
                                                                                        <NavLink className={this.state.reportedIssueSubsection === "reportedIssues" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("reportedIssueSubsection", "reportedIssues")}>
                                                                                            INCIDENT DAMAGES
                                                                                        </NavLink>
                                                                                    </NavItem>
                                                                                </Nav>
                                                                                {this.state.reportedIssueSubsection === "reportedIssues" &&
                                                                                    <div className="info-container" style={{ borderTopLeftRadius: "0px", padding: 3 }}>
                                                                                        <Table responsive>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    {IssuesTable.map(tableHeader =>
                                                                                                        <th key={tableHeader.id} style={{ fontWeight: 500 }}>{tableHeader.name}</th>
                                                                                                    )}
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {this.props.carCondition &&
                                                                                                    this.props.carCondition.incidentSet &&
                                                                                                    this.props.carCondition.incidentSet.edges &&
                                                                                                    this.props.carCondition.incidentSet.edges.filter(item => item.node.id === this.state.activeIncidentId).length > 0
                                                                                                    ? this.props.carCondition.incidentSet.edges.filter(item => item.node.id === this.state.activeIncidentId).map(incident => (
                                                                                                        incident.node.incidentdamageSet.edges.length > 0
                                                                                                            ? incident.node.incidentdamageSet.edges.map((incidentDamage, i) => (
                                                                                                                <tr key={`incidentDamage__${i}`}>
                                                                                                                    <td>
                                                                                                                        <small>{incidentDamage.node.damage.dateAdded ? <DatetimeRenderer datetime={incidentDamage.node.damage.dateAdded} placement="right" /> : "---"}</small>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {this.state.openModal === "editCarIssue" + i && <CarIssueModal open={this.state.openModal === "editCarIssue" + i} isUpdate carIssue={incidentDamage.node.damage} handleClose={() => this.toggleModal("editCarIssue" + i)} refetchQuery={this.props.refetchQuery} />}
                                                                                                                        <small>
                                                                                                                            {incidentDamage.node.damage.issueCategory ?
                                                                                                                                <a className="bos-custom-link" onClick={() => this.toggleModal("editCarIssue" + i)} target="_blank">
                                                                                                                                    {incidentDamage.node.damage.issueCategory.name}
                                                                                                                                </a>
                                                                                                                                : "---"}
                                                                                                                        </small>
                                                                                                                    </td>
                                                                                                                    <td><small>{incidentDamage.node.damage.status ? incidentDamage.node.damage.status : "---"}</small></td>
                                                                                                                    <td><small>{incidentDamage.node.projectedCost ? `$${incidentDamage.node.projectedCost}` : "---"}</small></td>
                                                                                                                    <td><small>{this.props.damageSeverityLevels && this.props.damageSeverityLevels.states && this.props.damageSeverityLevels.states.find(item => item.name === incidentDamage.node.severity).description}</small></td>
                                                                                                                    <td><small>{incidentDamage.node.damage.canPickup ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</small></td>
                                                                                                                    <td>
                                                                                                                        <small>
                                                                                                                            {incidentDamage.node.pictures && incidentDamage.node.pictures.edges ?
                                                                                                                                <div onClick={() => this.viewPictures(incidentDamage.node.pictures.edges)}>
                                                                                                                                    <i className="fa fa-file-image-o fa-lg" aria-hidden="true"></i>
                                                                                                                                    {incidentDamage.node.pictures.edges.length > 1 &&
                                                                                                                                        <a className="bos-custom-link" target="_blank">
                                                                                                                                            {" "}+{incidentDamage.node.pictures.edges.length - 1} more
                                                                                                                                        </a>}
                                                                                                                                </div> :
                                                                                                                                "No Pictures Found"}
                                                                                                                        </small>
                                                                                                                    </td>
                                                                                                                    <td><i id={"moveDamges" + i} class="fa fa-exchange" aria-hidden="true" onClick={() => this.setState({ openModal: "moveDamges" + i })}></i></td>
                                                                                                                    <UncontrolledTooltip target={"moveDamges" + i} >Move Damage</UncontrolledTooltip>
                                                                                                                    {this.state.openModal === "moveDamges" + i && this.state.carInspectionSubsection === "carIssue" &&
                                                                                                                        <Popover placement="left" target={"moveDamges" + i} isOpen={this.state.openModal === "moveDamges" + i && this.state.carInspectionSubsection === "carIssue"} toggle={() => this.toggleModal("moveDamges" + i)}>
                                                                                                                            <PopoverHeader>Move Damage</PopoverHeader>
                                                                                                                            <PopoverBody>
                                                                                                                                <Row>
                                                                                                                                    {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                                                                                                                                    {this.state.successMessage && <Col xs={12}><Alert color="success">{this.state.successMessage}</Alert></Col>}
                                                                                                                                </Row>
                                                                                                                                <Row>
                                                                                                                                    <Col>
                                                                                                                                        <FormGroup>
                                                                                                                                            <Label>Select the incident to move the damage</Label>
                                                                                                                                            <Select
                                                                                                                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                                                                                                                isLoading={this.props.tenantsLoading}
                                                                                                                                                options={this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges.length > 0
                                                                                                                                                    ? this.props.carCondition.incidentSet.edges.filter(item => item.node.id !== this.state.activeIncidentId).map((incident) => ({
                                                                                                                                                        value: incident.node.id,
                                                                                                                                                        label: `Incident #${incident.node.pk} - ${incident.node.dateAdded ? new Date(incident.node.dateAdded).toLocaleDateString() : "--"}`
                                                                                                                                                    }))
                                                                                                                                                    : []}
                                                                                                                                                placeholder="Select Incident"
                                                                                                                                                onChange={(incident) => this.setState({ selectedIncidentId: incident.value })}
                                                                                                                                            />
                                                                                                                                        </FormGroup>
                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                                <Row>
                                                                                                                                    <Col className="text-right">
                                                                                                                                        <Button className="Add-driver-modal-primary-btn" onClick={() => this.handleMoveDamage(incidentDamage.node.id)} disabled={this.state.loading || !(this.state.selectedIncidentId)}>{this.state.loading ? "Loading..." : "Move Damage"}</Button>{' '}
                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </PopoverBody>
                                                                                                                        </Popover>
                                                                                                                    }
                                                                                                                </tr>
                                                                                                            ))
                                                                                                            : <tr><td colSpan="8" style={{ color: "red" }}>No Damages Found!</td></tr>
                                                                                                    ))
                                                                                                    : <tr><td colSpan="8" style={{ color: "red" }}>No Incidents Found!</td></tr>
                                                                                                }

                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </div>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        <br />
                                                                        <Row>
                                                                            <Col>
                                                                                <Nav tabs>
                                                                                    <NavItem className={this.state.tab === "damageTransactions" ? "active" : "inactive"}>
                                                                                        <NavLink className={this.state.damageTransactionSubsection === "damageTransactions" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("damageTransactionSubsection", "damageTransactions")}>
                                                                                            DAMAGE TRANSACTIONS
                                                                                        </NavLink>
                                                                                    </NavItem>
                                                                                </Nav>
                                                                                {this.state.damageTransactionSubsection === "damageTransactions" &&
                                                                                    <div className="info-container" style={{ borderTopLeftRadius: "0px", padding: 3 }}>
                                                                                        <Table responsive>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th style={{ fontWeight: 500 }}>ID</th>
                                                                                                    <th style={{ fontWeight: 500 }}>Date Added</th>
                                                                                                    <th style={{ fontWeight: 500 }}>Charge Type</th>
                                                                                                    <th style={{ fontWeight: 500 }}>Amount</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges && this.props.carCondition.incidentSet.edges.length > 0 ? this.props.carCondition.incidentSet.edges.filter(item => item.node.id === this.state.activeIncidentId).map(incident =>
                                                                                                    incident.node.damageTransaction ?
                                                                                                        <tr>
                                                                                                            <td><small>{incident.node.damageTransaction.pk}</small></td>
                                                                                                            <td><small>{incident.node.damageTransaction.dateAdded ? <DatetimeRenderer datetime={incident.node.damageTransaction.dateAdded} placement="right" /> : "---"}</small></td>
                                                                                                            <td><small>{incident.node.damageTransaction.chargeType ? incident.node.damageTransaction.chargeType.name : "---"}<br /><small>{incident.node.damageTransaction.notes}</small></small></td>
                                                                                                            <td><small>{incident.node.damageTransaction.dynamicAmount ? `$${incident.node.damageTransaction.dynamicAmount}` : "---"}</small></td>
                                                                                                        </tr> : <tr><td colSpan={4} style={{ color: "red" }}>No Damage Transactions Found!</td></tr>
                                                                                                ) : <tr><td colSpan={4} style={{ color: "red" }}>No Incident Found!</td></tr>}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </div>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                    :
                                                                    <Row>
                                                                        <Col>
                                                                            <p>No incident found!</p>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    :
                                                    <div>

                                                    </div>
                                            : <p style={{ color: "red" }}>No Car Condition Found!</p>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <Nav tabs>
                                <NavItem className={this.state.tab === "walkaroundPictures" ? "active" : "inactive"}>
                                    <NavLink className={this.state.picturesSetSubsection === "walkaroundPictures" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("picturesSetSubsection", "walkaroundPictures")}>
                                        Walkaround Pictures
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "otherPictures" ? "active" : "inactive"}>
                                    <NavLink className={this.state.picturesSetSubsection === "otherPictures" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("picturesSetSubsection", "otherPictures")}>
                                        Other Pictures
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            {this.state.picturesSetSubsection === "walkaroundPictures" ?
                                <div className="info-container" style={{ maxHeight: "500px", overflow: "auto" }}>
                                    <Row>
                                        <Col>
                                            <Fragment>
                                                <div className="DocumentCardContainer" >
                                                    {this.props.carCondition && this.props.carCondition.carpicturesSet && this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType.toLowerCase() === "exterior walkaround").map((document, index) =>
                                                        <Card className="DocumentCard" key={index}>
                                                            <div className="DocumentCardHeader" onClick={() => this.viewPictures(this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType.toLowerCase() === "exterior walkaround"), index)}>
                                                                <p className="DocumentCardTitle">
                                                                    <span><i className="fa fa-picture-o" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                                                    <span>{document.node.pictureType} <small>({document.node.description})</small></span>
                                                                </p>
                                                                <p><i className="fa fa-arrows-alt"></i></p>
                                                            </div>
                                                            <div className="DocumentCardPreview" >
                                                                {document.node ? (
                                                                    <img className="DocumentCardPreviewEmbed DocViewerImage" src={document.node.pictureUrl} alt="Document" />
                                                                ) : ''}
                                                            </div>
                                                        </Card>
                                                    )}
                                                </div >
                                            </Fragment>
                                        </Col>
                                    </Row>
                                </div> :
                                <div className="info-container" style={{ maxHeight: "500px", overflow: "auto" }}>
                                    <Row>
                                        <Col>
                                            <Fragment>
                                                <div className="DocumentCardContainer" >
                                                    {this.props.carCondition && this.props.carCondition.carpicturesSet && this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType.toLowerCase() !== "exterior walkaround").map((document, index) =>
                                                        <Card className="DocumentCard" key={index}>
                                                            <div className="DocumentCardHeader" onClick={() => this.viewPictures(this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType.toLowerCase() !== "exterior walkaround"), index)}>
                                                                <p className="DocumentCardTitle">
                                                                    <span><i className="fa fa-picture-o" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                                                    <span>{document.node.pictureType} <small>({document.node.description})</small></span>
                                                                </p>
                                                                <p><i className="fa fa-arrows-alt"></i></p>
                                                            </div>
                                                            <div className="DocumentCardPreview" >
                                                                {document.node ? (
                                                                    <img className="DocumentCardPreviewEmbed DocViewerImage" src={document.node.pictureUrl} alt="Document" />
                                                                ) : ''}
                                                            </div>
                                                        </Card>
                                                    )}
                                                </div >
                                            </Fragment>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </div>
                    </>}
                </Col >
            </>
        )
    }
}

export default compose(
    withApollo,
    graphql(CarConditionDetailQuery, {
        options: ({ id }) => ({ variables: { id }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { carCondition, loading, refetch, variables } }) {
            return {
                carCondition, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: CarConditionDetailQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { carCondition: fetchMoreResult.carCondition }
                        },
                    })
                },
            }
        }
    }),
    graphql(IncidentDamageSeverityLevelQuery, {
        props({ data: { damageSeverityLevels, loading } }) {
            return {
                damageSeverityLevels,
                loading
            }
        }
    })
)(CarInspectionDetail)