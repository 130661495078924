import { initializeApp } from 'firebase/app'
import { getMessaging, getToken as getFirebaseToken, onMessage } from 'firebase/messaging'
import { getFirestore } from "firebase/firestore"


//https://blog.logrocket.com/push-notifications-with-react-and-firebase/

// var firebaseConfig = {
//   apiKey: "AIzaSyDZriN_6mIoUWTbs_-OEttLt9R58qYNVE0",
//   authDomain: "buggy-live-staging.firebaseapp.com",
//   databaseURL: "https://buggy-live-staging.firebaseio.com",
//   projectId: "buggy-live-staging",
//   storageBucket: "buggy-live-staging.appspot.com",
//   messagingSenderId: "641167209771",
//   appId: "1:641167209771:web:983c97395e0ec2edf5016e",
//   measurementId: "G-YZ7DWNSZWX"
// }

const firebaseConfig = {
  apiKey: "AIzaSyC3prAW9MjvzSTk4E87T_qd2U_-bCxoPcA",
  authDomain: "buggy-174216.firebaseapp.com",
  databaseURL: "https://buggy-174216-default-rtdb.firebaseio.com",
  projectId: "buggy-174216",
  storageBucket: "buggy-174216.appspot.com",
  messagingSenderId: "722998483799",
  appId: "1:722998483799:web:e060bbc2a4fccb0f51bc12"
}
// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig)

// Initialize Firestore
const firestoreDb = getFirestore(firebaseApp, "bos-realtimedb")

// Check if Firebase messaging is supported
let messaging = null
if (typeof window !== 'undefined' && 'Notification' in window) {
  try {
    messaging = getMessaging(firebaseApp)
  } catch (error) {
    console.error("Firebase messaging not supported or failed to initialize:", error)
  }
} else {
  console.log("Firebase messaging not supported on this browser.")
}

// Function to get the Firebase token
const getToken = (setTokenFound) => {
  if (messaging) {
    return getFirebaseToken(messaging, { vapidKey: 'BPkY6144XKqbu7zqAb-7a4usYyDE5Zlua9ykdANUC59LUl0_7hXktelU_IdMBps' })
      .then((currentToken) => {
        if (currentToken) {
          setTokenFound(currentToken)
        } else {
          console.log('No registration token available. Request permission to generate one.')
          setTokenFound(null)
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token.', err)
        setTokenFound(null)
      })
  } else {
    console.log("Messaging is not initialized.")
    setTokenFound(null)
  }
}

// Function to listen for messages
const onMessageListener = (callback) =>
  new Promise((resolve) => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        callback(payload)
        resolve(payload)
      })
    }
  })


export { messaging, getToken, onMessageListener, firestoreDb }
